import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useSnackbar } from 'notistack';

import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Grid,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { X as XIcon } from 'react-feather';
import { Redirect } from 'react-router';

import { Autocomplete } from '@mui/material';

import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import useStyles from './styles';
import { putEditEngelProduct } from '../../../requests/api/engelproducts';

function EngelProductsAssociation({
  products, allProducts, productsAssociatedEngel,
  totalProducts, page,
  limit, setPage, setLimit,
  setSearchValueProduct,
  setEngelProductPageEdit,
  newEngelProduct,
  brands,
  searchBrand,
  supermarkets,
  searchSupermarkets,
  setEngelProductBrand,
  setEngelProductSupermarket,
  associatedLoading,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedProducts, setSelectedProducts] = useState(productsAssociatedEngel);
  const [selectedProductsObjects, setSelectedProductsObjects] = useState([]);
  const [currentProducts, setCurrentProducts] = useState(products);
  const allCurrentProducts = allProducts;
  const [query, setQuery] = useState('');

  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleSearchValue = () => {
    if (query.trim()) {
      setSearchValueProduct(query);
    }
  };
  const handleSetBrandValue = (value) => {
    setEngelProductBrand(value);
  };

  const handleSetSupermarketValue = (value) => {
    setEngelProductSupermarket(value);
  };

  const handleSelectOneProduct = (event, product) => {
    if (!selectedProducts.includes(product.id)) {
      setSelectedProducts((prevSelected) => [...prevSelected, product.id]);
      setSelectedProductsObjects((prevSelected) => [...prevSelected, product]);
    } else {
      setSelectedProducts((prevSelected) => prevSelected.filter((id) => id !== product.id));
      // eslint-disable-next-line max-len
      setSelectedProductsObjects((prevSelected) => prevSelected.filter((productSelected) => productSelected.id !== product.id));
    }
  };

  const selectedSomeProducts = selectedProducts.length > 0
    && selectedProducts.length < currentProducts.length;

  const handlePreviousStep = () => {
    setSearchValueProduct('');
    setEngelProductPageEdit(0);
  };

  const handleSubmit = async () => {
    if (selectedProducts.length === 0) {
      enqueueSnackbar('Debes seleccionar por lo menos un producto', {
        variant: 'error',
      });
      return;
    }
    try {
      const response = await putEditEngelProduct({
        ...newEngelProduct,
        productIds: selectedProducts,
      });
      if (response.success) {
        setSubmitSuccess(true);
        enqueueSnackbar(response.message, {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(response.message, {
          variant: 'warning',
        });
      }
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: 'warning',
      });
    }
  };
  useEffect(() => {
    setSelectedProductsObjects(allCurrentProducts
      .filter((product) => selectedProducts.includes(product.id)) || []);
  }, [allProducts]);

  useEffect(() => {
    setCurrentProducts(products);
  }, [products]);

  if (submitSuccess) {
    return <Redirect to="/engel/engel-products" />;
  }
  return (
    <Box p={2}>
      {associatedLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Paper elevation={3}>
            <Box p={2}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    fullWidth
                    color="secondary"
                    placeholder="Nombre, SKU, EAN"
                    type="text"
                    variant="outlined"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {query && (
                            <IconButton
                              onClick={() => {
                                setSearchValueProduct('');
                                setQuery('');
                              }}
                            >
                              <XIcon />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleSearchValue}
                    fullWidth
                  >
                    Buscar
                  </Button>
                </Grid>
                <Grid item xs={12} md={2}>
                  {brands.length > 0 && (
                    <Autocomplete
                      id="brands-autocomplete"
                      options={brands}
                      multiple
                      value={searchBrand}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, v) => handleSetBrandValue(v)}
                      limitTags={1}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Filtrar por Marca"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: '0.8rem' }, // Ajusta el tamaño de la fuente
                          }}
                          InputLabelProps={{
                            style: { fontSize: '0.8rem' }, // Ajusta el tamaño de la fuente de la etiqueta
                          }}
                        />
                      )}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            key={option.id}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={2}>
                  {supermarkets.length > 0 && (
                    <Autocomplete
                      id="supermarkets-autocomplete"
                      options={supermarkets}
                      multiple
                      value={searchSupermarkets}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, v) => handleSetSupermarketValue(v)}
                      limitTags={1}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Filtrar por supermercado"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: '0.8rem' }, // Ajusta el tamaño de la fuente
                          }}
                          InputLabelProps={{
                            style: { fontSize: '0.8rem' }, // Ajusta el tamaño de la fuente de la etiqueta
                          }}
                        />
                      )}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            key={option.id}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <Box mt={3}>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox indeterminate={selectedSomeProducts} />
                    </TableCell>
                    <TableCell>Descripción</TableCell>
                    <TableCell>Marca</TableCell>
                    <TableCell>Supermercado</TableCell>
                    <TableCell>Unidades</TableCell>
                    <TableCell>SKU</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.length !== 0
                    && products.map((product) => {
                      const isProductSelected = selectedProducts.includes(product.id);
                      return (
                        <TableRow key={product.id}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isProductSelected}
                              onChange={(event) => handleSelectOneProduct(event, product)}
                              value={isProductSelected}
                            />
                          </TableCell>
                          <TableCell>{product.description}</TableCell>
                          <TableCell>{product.brand}</TableCell>
                          <TableCell>{product.supermarket_name}</TableCell>
                          <TableCell>{product.units_per_product}</TableCell>
                          <TableCell>{product.sku}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              <Box mt={2} className={classes.tablePagination}>
                <TablePagination
                  component="div"
                  count={totalProducts}
                  onPageChange={(e, newPage) => setPage(newPage)}
                  onRowsPerPageChange={(e) => {
                    setLimit(e.target.value);
                    setPage(0);
                  }}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage="Filas por página"
                />
              </Box>
            </Paper>
          </Box>
          <Typography variant="h3" color="textPrimary" className={classes.tittle}>
            Productos seleccionados
          </Typography>
          <Box mt={3}>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Descripción</TableCell>
                    <TableCell>Marca</TableCell>
                    <TableCell>Supermercado</TableCell>
                    <TableCell>Unidades</TableCell>
                    <TableCell>SKU</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedProductsObjects.length !== 0
                    && selectedProductsObjects.map((product) => (
                      <TableRow key={product.id}>
                        <TableCell>{product.description}</TableCell>
                        <TableCell>{product.brand}</TableCell>
                        <TableCell>{product.supermarket_name}</TableCell>
                        <TableCell>{product.units_per_product}</TableCell>
                        <TableCell>{product.sku}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <Box mt={2} className={classes.tableSelectedProduct}>
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handlePreviousStep}
                    style={{ marginRight: '10px' }}
                  >
                    Atrás
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={classes.orangeButton}
                    onClick={handleSubmit}
                  >
                    Editar Producto Engel
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Box>
        </>
      )}
    </Box>
  );
}

EngelProductsAssociation.propTypes = {
  className: PropTypes.string,
  products: PropTypes.array,
  allProducts: PropTypes.array,
  productsAssociatedEngel: PropTypes.array,
  limit: PropTypes.number,
  brands: PropTypes.array,
  supermarkets: PropTypes.array,
  totalProducts: PropTypes.number,
  page: PropTypes.number,
  setPage: PropTypes.func,
  setLimit: PropTypes.func,
  setSearchValue: PropTypes.func,
  setSearchValueProduct: PropTypes.func,
  setEngelProductPageEdit: PropTypes.func,
  newEngelProduct: PropTypes.object,
  searchBrand: PropTypes.array,
  searchSupermarkets: PropTypes.array,
  setEngelProductBrand: PropTypes.func,
  setEngelProductSupermarket: PropTypes.func,
  associatedLoading: PropTypes.bool,
};

export default EngelProductsAssociation;
