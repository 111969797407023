/* eslint-disable no-extra-boolean-cast */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import {
  Search as SearchIcon,
  ArrowRightCircle as ArrowIcon,
  Star as StarIcon,
  XCircle as XCircleIcon,
  Settings as SettingsIcon,
} from 'react-feather';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';

import {
  setProductQuery,
  setProductSupermarket,
  setProductCategory,
  setProductSubcategory,
  setProductPage,
  setProductLimit,
  setProductBrand,
} from '../../../actions/productsList';

import useStyles from './styles';
import alphabeticSort from '../../../utils/alphabeticSort';
import BrandSelector from '../../../components/Inputs/Autocomplete/BrandSelector';

function Results({
  className,
  products,
  supermarkets,
  categories,
  setCategories,
  subcategories,
  brands,
  setSubcategories,
  originalCategories,
  count,
  sort,
  setSort,
  sortOptions,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [currentQuery, setCurrentQuery] = useState('');
  const [currentCategories, setCurrentCategories] = useState([]);
  const [currentSubcategories, setCurrentSubcategories] = useState([]);
  const [currentProducts, setCurrentProducts] = useState(products);
  const account = useSelector((state) => state.account);

  const {
    productSupermarket,
    productCategory,
    productSubcategory,
    productBrand,
    productPage,
    productLimit,
  } = useSelector((state) => state.productsList);

  useEffect(() => {
    dispatch(setProductQuery(currentQuery));
  }, [currentQuery]);

  useEffect(() => { setCurrentProducts(products); }, [products]);

  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);

  useEffect(() => {
    setCurrentCategories(categories);
  }, [categories]);

  useEffect(() => {
    setCurrentSubcategories(subcategories);
  }, [subcategories]);

  const handleQueryChange = (event) => {
    event.persist();
    setCurrentQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    event.persist();
    setSort(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    dispatch(setProductPage(newPage));
  };

  const handleLimitChange = (event) => {
    dispatch(setProductLimit(event.target.value));
  };

  const handleChange = (type, newVal) => {
    if (!!newVal) {
      const ides = newVal.map((val) => val.id);
      dispatch(setProductPage(0));
      if (type === 'supermarket') {
        dispatch(setProductSupermarket(newVal));
        dispatch(setProductCategory([]));
        dispatch(setProductSubcategory([]));
        let subcatList = [];
        if (ides.length === 0) {
          setCategories(originalCategories);
          originalCategories.forEach((element) => {
            subcatList = subcatList.concat(element.Subcategories);
          });
        } else {
          const filteredCats = originalCategories.filter((item) => ides.includes(item.supermarket_id)).sort((a, b) => alphabeticSort(a, b));
          setCategories(filteredCats);
          filteredCats.forEach((element) => {
            subcatList = subcatList.concat(element.Subcategories);
          });
        }
        setSubcategories(subcatList.sort((a, b) => alphabeticSort(a, b)));
      } else if (type === 'category') {
        dispatch(setProductCategory(newVal));
        let subcatList = [];
        dispatch(setProductCategory(newVal));
        dispatch(setProductSubcategory([]));
        if (ides.length === 0) {
          originalCategories.forEach((element) => {
            subcatList = subcatList.concat(element.Subcategories);
          });
        } else {
          originalCategories.forEach((element) => {
            if (ides.includes(element.id)) {
              subcatList = subcatList.concat(element.Subcategories);
            }
          });
        }
        setSubcategories(subcatList.sort((a, b) => alphabeticSort(a, b)));
      } else if (type === 'subcategory') {
        dispatch(setProductSubcategory(newVal));
      }
    }
  };

  const handleBrandSelection = (selectedBrands) => {
    dispatch(setProductBrand(selectedBrands));
  };

  return (
    <Card
      className={clsx(classes.rootResults, className)}
      {...rest}
    >
      <Divider />
      <Box
        p={2}
        minHeight={56}
        display="flex"
        alignItems="center"
      >
        <Grid container spacing={1} >
          <Grid item xs={2} md={4}>
            <TextField
              className={classes.queryField}
              onChange={handleQueryChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      color="action"
                    >
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
                endAdornment: currentQuery ? (
                  <IconButton size="small" onClick={() => {
                    setCurrentQuery('');
                    dispatch(setProductQuery(''));
                  }}>
                    <XCircleIcon />
                  </IconButton>
                ) : null,
              }}
              placeholder="Buscar por sku o por descripción"
              value={currentQuery}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={0.5} md={6} >
            <Button
              color="secondary"
              variant="contained"
              className={classes.searchAction}
              onClick={() => dispatch(setProductQuery(currentQuery))}
            >
              <SvgIcon >
                <SearchIcon />
              </SvgIcon>
            </Button>
          </Grid>
          <Grid item xs={3} md={2}>
            <TextField
              className={classes.queryField}
              label="Orden"
              name="order"
              onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              value={sort}
              variant="outlined"
            >
              {sortOptions.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={2} md={3} >
            {supermarkets.length > 0 && <Autocomplete
              id="supermarkets-autocomplete"
              options={supermarkets}
              multiple
              value={productSupermarket}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => handleChange('supermarket', v)}
              limitTags={1}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.queryField}
                  label='Supermercados'
                  variant='outlined'
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
            />
            }
          </Grid>
          <Grid item xs={3}>
            {currentCategories.length > 0 && <Autocomplete
              id="categories-autocomplete"
              options={currentCategories}
              multiple
              value={productCategory}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => handleChange('category', v)}
              limitTags={1}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.queryField}
                  label='Categoría'
                  variant='outlined'
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name} ({supermarkets.filter((sup) => sup.id === option.supermarket_id)[0].name})
                </li>
              )}
            />
            }
          </Grid>
          <Grid item xs={3}>
            {<Autocomplete
              id="subcategories-autocomplete"
              options={currentSubcategories}
              multiple
              value={productSubcategory}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => handleChange('subcategory', v)}
              limitTags={1}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.queryField}
                  label='Subcategoría'
                  variant='outlined'
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name} ({supermarkets.filter((sup) => sup.id === option.supermarket_id)[0].name})
                </li>
              )}
            />
            }
          </Grid>
          <Grid item xs={2} md={3}>
            <BrandSelector
              productBrand={productBrand}
              onBrandChange={handleBrandSelection}
            />
          </Grid>
        </Grid>
      </Box>

      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='center'>
                  Nuevo
                </TableCell>
                <TableCell align='center'>
                  Sku
                </TableCell>
                <TableCell align='center'>
                  Supermercado
                </TableCell>
                <TableCell align='center'>
                  Descripcion
                </TableCell>
                <TableCell align='center'>
                  Marca
                </TableCell>
                <TableCell align='center'>
                  Categoría
                </TableCell>
                <TableCell align='center'>
                  Subcategoría
                </TableCell>
                <TableCell align='center'>
                  Unidades
                </TableCell>
                <TableCell align='center'>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentProducts.map((product) => (
                  <TableRow
                    hover
                    key={product.id}
                  >
                    <TableCell align='center'>{new Date(product.created_at) > lastMonth ? <StarIcon/> : ''}</TableCell>
                    <TableCell align='center'>{product.sku}</TableCell>
                    <TableCell align='center'>{product.supermarket_name}</TableCell>
                    <TableCell>{product.description}</TableCell>
                    <TableCell align='center'>{product.brand}</TableCell>
                    <TableCell align='center'>{product.category_name}</TableCell>
                    <TableCell align='center'>{product.subcategory_name}</TableCell>
                    <TableCell align='center'>{product.units_per_product}</TableCell>
                    <TableCell align='center' width={'10%'}>
                      {account.user.role === 'admin'
                        ? <Tooltip title="Editar Producto">
                            <IconButton
                              component={RouterLink}
                              to={`/datos/products/${product.id}/editar`}
                            >
                              <SvgIcon fontSize="small">
                                <SettingsIcon />
                              </SvgIcon>
                            </IconButton>
                          </Tooltip>
                        : null }
                      <Tooltip title="Ver Gráficos">
                        <IconButton
                          component={RouterLink}
                          to={`/datos/products/${product.id}/graphs`}
                        >
                          <SvgIcon fontSize="small">
                            <ArrowIcon />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={count}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={(productPage > 0 && currentProducts.length < productLimit) ? 0 : productPage}
        rowsPerPage={productLimit}
        rowsPerPageOptions={[5, 10, 25, 50]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  products: PropTypes.array,
  subcategories: PropTypes.array,
  supermarkets: PropTypes.array,
  categories: PropTypes.array,
  brands: PropTypes.array,
  originalProducts: PropTypes.array,
  setCategories: PropTypes.func,
  setSubcategories: PropTypes.func,
  originalCategories: PropTypes.array,
  count: PropTypes.number,
  sort: PropTypes.string,
  setSort: PropTypes.func,
  sortOptions: PropTypes.array,
};

Results.defaultProps = {
  products: [],
};

export default Results;
