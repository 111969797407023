import React, { useState, useEffect } from 'react';
import {
  Box, Card, CardContent, IconButton, TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Autocomplete, MenuItem } from '@mui/material';
import { PlusCircle } from 'react-feather';
import useStyles from './styles';

const DAYS_OF_WEEK = [
  {
    value: 'monday',
    label: 'Lunes',
  },
  {
    value: 'tuesday',
    label: 'Martes',
  },
  {
    value: 'wednesday',
    label: 'Miercoles',
  },
  {
    value: 'thursday',
    label: 'Jueves',
  },
  {
    value: 'friday',
    label: 'Viernes',
  },
];

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const UpsertInformationHeader = ({
  selectedDay,
  setSelectedDay,
  handleAddEmail,
  categories,
  selectedCategories,
  setSelectedCategories,
  brands,
  selectedBrands,
  setSelectedBrands,
  superMarkets,
  selectedSuperMarkets,
  setSelectedSuperMarkets,
}) => {
  const classes = useStyles();

  const [emailToAdd, setEmailToAdd] = useState('');
  const [error, setError] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);

  useEffect(() => {
    if (selectedSuperMarkets.length > 0) {
      const selectedSuperMarketIds = selectedSuperMarkets.map((sm) => sm.id);

      const newFilteredCategories = categories
        .filter((c) => c.supermarket_id === selectedSuperMarketIds[0]);
      setFilteredCategories(newFilteredCategories);
    } else {
      setFilteredCategories(categories);
    }
  }, [selectedSuperMarkets, categories]);

  const handleSubmitEmail = () => {
    if (!emailRegex.test(emailToAdd)) {
      setError('Email no tiene el formato correcto');
      return;
    }
    handleAddEmail(emailToAdd, setEmailToAdd);
  };

  return (
    <Card className={classes.upsertInfoCardContainer}>
      <CardContent className={classes.upsertInfoCardContentContainer}>
        <Box className={classes.upsertInfoCardTextfieldContainer}>
          <TextField
            id="send-day-information"
            select
            fullWidth
            variant='outlined'
            label="Dia de envío información"
            value={selectedDay}
            onChange={(e) => setSelectedDay(e.target.value)}
          >
            {DAYS_OF_WEEK.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box className={classes.upsertInfoCardTextfieldContainer}>
          <Autocomplete
            multiple
            id="categories-alarm-autocomplete"
            options={filteredCategories}
            disableClearable
            value={selectedCategories}
            getOptionLabel={(option) => option.name}
            onChange={(e, v) => setSelectedCategories(v)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Categorías"
                variant="outlined"
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )}
          />
        </Box>
        <Box className={classes.upsertInfoCardTextfieldContainer}>
          <Autocomplete
            multiple
            id="brands-alarm-autocomplete"
            options={brands}
            disableClearable
            value={selectedBrands}
            getOptionLabel={(option) => option.name}
            onChange={(e, v) => setSelectedBrands(v)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Marcas"
                variant="outlined"
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )}
          />
        </Box>
        <Box className={classes.upsertInfoCardTextfieldContainer}>
          <Autocomplete
            multiple
            id="supermarkets-alarm-autocomplete"
            options={superMarkets}
            disableClearable
            value={selectedSuperMarkets}
            getOptionLabel={(option) => option.name}
            onChange={(e, v) => setSelectedSuperMarkets(v)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Supermercado"
                variant="outlined"
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )}
          />
        </Box>
        <Box display='flex' className={classes.upsertInfoCardTextfieldContainer}>
          <TextField
            id="add-email"
            fullWidth
            variant='outlined'
            label="Añade correo para envío"
            value={emailToAdd}
            onChange={(e) => { setEmailToAdd(e.target.value); setError(''); }}
            error={error !== ''}
            helperText={error}
          />
          <IconButton onClick={handleSubmitEmail}>
            <PlusCircle />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
};

UpsertInformationHeader.propTypes = {
  selectedDay: PropTypes.string,
  setSelectedDay: PropTypes.func,
  handleAddEmail: PropTypes.func,
  categories: PropTypes.array,
  selectedCategories: PropTypes.array,
  setSelectedCategories: PropTypes.func,
  brands: PropTypes.array,
  selectedBrands: PropTypes.array,
  setSelectedBrands: PropTypes.func,
  superMarkets: PropTypes.array,
  selectedSuperMarkets: PropTypes.array,
  setSelectedSuperMarkets: PropTypes.func,
};

export default UpsertInformationHeader;
